import { buildQuery, currentENVData } from "../helpers";
import { authService } from "services";

export const userService = {
  getUsers,
};

function getUsers(options) {
  const requestOptions = {
    method: "GET",
  };

  const url = `${currentENVData.tradeAllyURL}/api/v1/admin/customer/all-ff${
    options ? `?${buildQuery(options)}` : ""
  }`;

  return authService
    .apiGate(url, requestOptions)
    .then((analytics) => analytics?.data);
}
