import { userService } from "services";
import { userConstants } from "constants";

function getUsers(options, clear) {
  return (dispatch) => {
    function request() {
      return { type: userConstants.FETCH_USERS_REQUEST };
    }
    function success(users) {
      return { type: userConstants.FETCH_USERS_SUCCESS, users, clear };
    }
    function failure(error) {
      return { type: userConstants.FETCH_USERS_ERROR, error };
    }

    dispatch(request());

    userService.getUsers(options).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        if (error.message) {
          dispatch(failure(error.message));
        }
      }
    );
  };
}

export const userActions = {
  getUsers,
};
