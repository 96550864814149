import React, { useEffect, useState } from "react";
import Auth from "components/Auth";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "actions";
import { useNavigate } from "react-router-dom";

const Form = ({ user, onChange, focused, setFocused }) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <>
      <div className="mb-2">
        <p className="text-sm text-[#3F3D56] mb-1">Email</p>
        <input
          type={"email"}
          name="email"
          onFocus={() => setFocused("email")}
          onBlur={() => setFocused(false)}
          readOnly={focused !== "email"}
          value={user.email || ""}
          onChange={onChange}
          className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none border border-[#DFE1E6] rounded-md"
          required={true}
        />
      </div>
      <div className="mb-2">
        <p className="text-sm text-[#3F3D56] mb-1">Password</p>
        <div
          className={`${
            focused === "password" && "border-[#16C6A4]"
          } w-full h-[50px] rounded-md border border-[#DFE1E6] flex`}
        >
          <input
            type={visibility ? "text" : "password"}
            name="password"
            onFocus={() => setFocused("password")}
            onBlur={() => setFocused(false)}
            readOnly={focused !== "password"}
            value={user.password || ""}
            onChange={onChange}
            className="duration-300 w-full py-3 px-2 bg-none outline-none rounded-md"
            required={true}
          />
          <div
            onClick={() => setVisibility((s) => !s)}
            className="w-[50px] h-full shrink-0 flex justify-center items-center select-none cursor-pointer"
          >
            {visibility ? (
              <span className="material-icons">visibility</span>
            ) : (
              <span className="material-icons">visibility_off</span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 mb-8 flex justify-between text-xs">
        <p className="text-[#B1A9A9] mb-1">Remember me</p>
        <p className="text-[#E35328] mb-1">Forget password</p>
      </div>
    </>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [focused, setFocused] = useState(false);
  const auth = useSelector((state) => state.auth);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(authActions.login(user));
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setUser((u) => ({
      ...u,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (auth.user?.token) {
      navigate("/dashboard/index");
    }
    // eslint-disable-next-line
  }, [auth.user]);

  return (
    <Auth
      type="login"
      label="Sign In"
      subtext={"please fill your details to access your account."}
      focused={focused}
      onSubmit={onSubmit}
      form={
        <Form
          focused={focused}
          setFocused={setFocused}
          user={user}
          onChange={onChange}
        />
      }
    />
  );
};

export default Login;
