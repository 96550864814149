import { userConstants } from "constants";

const initialState = {
  users: {},
  usersLoading: false,
  usersError: false,
  analytics: {},
  analyticsLoading: false,
  analyticsError: false,
};

export function user(state = initialState, action) {
  switch (action.type) {
    case userConstants.FETCH_USERS_REQUEST:
      return {
        ...state,
        usersLoading: true,
      };
    case userConstants.FETCH_USERS_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        users: {
          ...action.users,
          data: [...(state.users?.data || []), ...action.users?.data],
        },
      };
    case userConstants.FETCH_USERS_ERROR:
      return {
        ...state,
        usersLoading: false,
        usersError: action.error,
      };
    default:
      return state;
  }
}
