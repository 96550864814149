import React, { useEffect, useState } from "react";
import { ReactComponent as AuthLogo } from "assets/images/logo-with-text.svg";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "actions";
import { useNavigate } from "react-router-dom";
import Loading from "components/common/Loading";

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [otp, setOtp] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(authActions.verifyUser(otp));
  };

  useEffect(() => {
    if (auth.verifyUserSuccess) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [auth.verifyUserSuccess]);

  return (
    <div className="w-full h-screen flex flex-col no-shrink overflow-y-auto">
      <div className="w-1/3 py-[80px] m-auto">
        <div className="h-[80px] w-full bg-[#16C6A4] rounded-t-lg flex justify-center items-center mb-4">
          <AuthLogo className="h-[30px] w-auto" />
        </div>
        <div className="border border-gray rounded-b-lg p-8 text-center">
          <p className="text-sm mb-6">please enter the verification code</p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            renderInput={(props) => <input className="otp-input" {...props} />}
            numInputs={6}
            inputType={"number"}
            containerStyle="otp-input flex justify-center"
            renderSeparator={<span className={`mx-[3px]`}></span>}
          />
          <p className="text-xs text-[#E35328] my-6">
            Code expires in 2 minutes
          </p>
          <button
            type="button"
            onClick={onSubmit}
            className="bg-[#16C6A4] w-full h-[50px] mt-6 rounded-lg text-white relative"
          >
            {auth.verifyUserLoading ? <Loading /> : "Verify"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Verification;
