export const authConstants = {
  LOGOUT_USER: "LOGOUT_TA_USER",
  LOGIN_REQUEST: "TA_LOGIN_REQUEST",
  LOGIN_SUCCESS: "TA_LOGIN_SUCCESS",
  LOGIN_FAILURE: "TA_LOGIN_FAILURE",
  REGISTER_REQUEST: "TA_REGISTER_REQUEST",
  REGISTER_SUCCESS: "TA_REGISTER_SUCCESS",
  REGISTER_FAILURE: "TA_REGISTER_FAILURE",
  FETCH_SUBUSER_REQUEST: "FETCH_SUBUSER_REQUEST",
  FETCH_SUBUSER_SUCCESS: "FETCH_SUBUSER_SUCCESS",
  FETCH_SUBUSER_ERROR: "FETCH_SUBUSER_ERROR",
  ADD_NEW_SUBUSER_REQUEST: "ADD_NEW_SUBUSER_REQUEST",
  ADD_NEW_SUBUSER_SUCCESS: "ADD_NEW_SUBUSER_SUCCESS",
  ADD_NEW_SUBUSER_ERROR: "ADD_NEW_SUBUSER_ERROR",
  DELETE_SUBUSER_REQUEST: "DELETE_SUBUSER_REQUEST",
  DELETE_SUBUSER_SUCCESS: "DELETE_SUBUSER_SUCCESS",
  DELETE_SUBUSER_ERROR: "DELETE_SUBUSER_ERROR",
  FORGET_PASSWORD_REQUEST: "TA_FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_SUCCESS: "TA_FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_ERROR: "TA_FORGET_PASSWORD_ERROR",
  RESET_REQUEST: "TA_RESET_REQUEST",
  RESET_SUCCESS: "TA_RESET_SUCCESS",
  RESET_ERROR: "TA_RESET_ERROR",
  SEND_VERIFY_REQUEST: "TA_SEND_VERIFY_REQUEST",
  SEND_VERIFY_SUCCESS: "TA_SEND_VERIFY_SUCCESS",
  SEND_VERIFY_ERROR: "TA_SEND_VERIFY_ERROR",
  SUBUSER_DATA_TO_EDIT: "SUBUSER_DATA_TO_EDIT",
  SUBUSER_DATA_TO_DELETE: "SUBUSER_DATA_TO_DELETE",
  TOGGLE_UPDATE_PASSWORD_MODAL: "TOGGLE_UPDATE_PASSWORD_MODAL",
  TOGGLE_CREATE_SUBUSER_MODAL: "TOGGLE_CREATE_SUBUSER_MODAL",
  TOGGLE_DELETE_SUBUSER_MODAL: "TOGGLE_DELETE_SUBUSER_MODAL",
  TOGGLE_EDIT_SUBUSER_MODAL: "TOGGLE_EDIT_SUBUSER_MODAL",
  VERIFY_REQUEST: "TA_VERIFY_REQUEST",
  VERIFY_SUCCESS: "TA_VERIFY_SUCCESS",
  VERIFY_ERROR: "TA_VERIFY_ERROR",
  UPDATE_USER_REQUEST: "TA_UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "TA_UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "TA_UPDATE_USER_ERROR",
  UPDATE_USERIMAGE_REQUEST: "TA_UPDATE_USERIMAGE_REQUEST",
  UPDATE_USERIMAGE_SUCCESS: "TA_UPDATE_USERIMAGE_SUCCESS",
  UPDATE_USERIMAGE_ERROR: "TA_UPDATE_USERIMAGE_ERROR",
  UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_ERROR: "UPDATE_USER_PASSWORD_ERROR",
  UPDATE_PASSWORD_STATES_TO_DEFAULT: "  UPDATE_PASSWORD_STATES_TO_DEFAULT",
  UPDATE_SUBUSER_STATES_TO_DEFAULT: "  UPDATE_SUBUSER_STATES_TO_DEFAULT",
  EDIT_SUBUSER_DATAILS_REQUEST: "EDIT_SUBUSER_DATAILS_REQUEST",
  EDIT_SUBUSER_DATAILS_SUCCESS: "EDIT_SUBUSER_DATAILS_SUCCESS",
  EDIT_SUBUSER_DATAILS_ERROR: "EDIT_SUBUSER_DATAILS_ERROR",
  DEACTIVATE_TA_ACCOUNT: "DEACTIVATE_TA_ACCOUNT",
  DEACTIVATE_TA_ACCOUNT_SUCCESS: "DEACTIVATE_TA_ACCOUNT_SUCCESS",
  DEACTIVATE_TA_ACCOUNT_ERROR: "DEACTIVATE_TA_ACCOUNT_ERROR",
  ACCOUNT_DELETION_COMPLETED: "ACCOUNT_DELETION_COMPLETED",
};
