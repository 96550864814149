import CustomToast from "components/common/CustomToast";
import { currentENVData } from "helpers";
import decode from "jwt-decode";
import toast from "react-hot-toast";

export const authService = {
  loggedIn,
  apiGate,
  getProfile,
  getToken,
  login,
  register,
  forgotPassword,
  resetPassword,
  verifyUser,
  sendVerifyMail,
  updateUserProfile,
  updateUserProfileImage,
  updateUserPassword,
  deactivateAccount,
};

async function login(credentials) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const requestOptions = {
    method: "POST",
    body: JSON.stringify(credentials),
  };

  console.log(currentENVData);

  const res = await fetch(
    `${currentENVData.tradeAllyURL}/api/v1/admin/auth/login`,
    { headers, ...requestOptions }
  )
    .then((res) => handleResponse(res))
    .then((response) => {
      let user;
      user = response?.data?.data?.user;
      user._token = response?.data?.data?.token;
      user.account = decode(response?.data?.data?.token);

      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("ta-admin", JSON.stringify(user));

      return response;
    });

  return res;
}

async function register(user) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(user),
  };

  const res = await apiGate(
    `${currentENVData.tradeAllyURL}/api/v1/admin/auth/signup`,
    requestOptions
  );
  return res;
}

async function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(email),
  };

  const res = await apiGate(
    `${currentENVData.transitURL}/api/v1/admin/auth/password/forget`,
    requestOptions
  );
  return res;
}

async function resetPassword(credentials) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(credentials),
  };

  const res = await apiGate(
    `${currentENVData.transitURL}/api/v1/admin/auth/password/reset`,
    requestOptions
  );
  return res;
}

async function sendVerifyMail(email) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(email),
  };

  const res = await apiGate(
    `${currentENVData.transitURL}/auth/users/send/verification`,
    requestOptions
  );
  return res;
}

async function verifyUser(emailCodeObj) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(emailCodeObj),
  };

  const res = await apiGate(
    `${currentENVData.tradeAllyURL}/api/v1/admin/auth/code/verify`,
    requestOptions
  );
  return res;
}

function apiGate(url, options, noKey) {
  // performs api calls sending the required authentication headers
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (!noKey) {
    headers["x-access-key"] = `Bearer ${currentENVData.apiKey}`;
  }

  // Setting Authorization header
  // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
  if (loggedIn()) {
    headers.Authorization = "Bearer " + getToken();
  }

  return fetch(url, {
    headers,
    ...options,
  })
    .then((res) => handleResponse(res))
    .then((response) => {
      return response;
    });
}

function getProfile() {
  // Using jwt-decode npm package to decode the token
  const token = getToken();

  if (!token) return false;

  return decode(getToken());
}

function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from store

  return !!token && !isTokenExpired(token); // handwaiving here
}

function getToken() {
  // Retrieves the user token from store
  const user = JSON.parse(localStorage.getItem("ta-admin"));

  return user ? user._token : user;
}

function isTokenExpired(token) {
  if (!token) return false;

  try {
    const decoded = decode(token);

    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    let data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        toast.custom((t) => (
          <CustomToast t={t} message={"Permission denied!"} type="error" />
        ));
      }

      data.status = response.status;
      const error = (data && data) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

async function updateUserProfile(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await apiGate(
    `${currentENVData.transitURL}/users`,
    requestOptions
  );
  return res;
}

async function updateUserProfileImage(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };
  const res = await apiGate(
    `${currentENVData.transitURL}/users/`,
    requestOptions
  );
  return res;
}

async function updateUserPassword(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
  };

  const res = await apiGate(
    `${currentENVData.transitURL}/auth/users/password/update`,
    requestOptions
  );
  return res;
}

async function deactivateAccount() {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({}),
  };

  const res = await apiGate(
    `${currentENVData.transitURL}/auth/users/deactivate`,
    requestOptions
  );

  return res;
}
