import React, { useEffect, useState } from "react";
import Auth from "components/Auth";
import ReactFlagsSelect from "react-flags-select";
import countryList from "variables/countries";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { currentENVData, getCountryfromLatLng } from "helpers";
import { authActions } from "actions";
import { useDispatch, useSelector } from "react-redux";
import ModalContainer from "components/common/ModalContainer";
import SignupVerification from "./modals/SignupVerification";
import Verification from "./Verification";

const Form = ({ focused, user, setUser, onChange, setFocused }) => {
  const [selected, setSelected] = useState("NG");
  const [map, setMap] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selected && countries.length > 0) {
      setUser((data) => ({
        ...data,
        mobileExtension: countries.find((i) => i.abbreviation === selected)
          ?.codes[0],
      }));
    }
    // eslint-disable-next-line
  }, [selected, countries]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: currentENVData.googleAPIKey,
  });

  const getGeoLocation = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      let country = await getCountryfromLatLng({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      setSelected(
        country.details?.find((c) => c.types.includes("country"))?.short_name
      );
    });
  };

  useEffect(() => {
    if (map) {
      getGeoLocation();
    }
  }, [map]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  return (
    <>
      {isLoaded ? (
        <GoogleMap center={{ lat: 10, lng: 10 }} zoom={10} onLoad={onLoad}>
          <></>
        </GoogleMap>
      ) : (
        <></>
      )}
      <div className="mb-2">
        <p className="text-sm text-[#3F3D56] mb-1">Full Name</p>
        <input
          type={"text"}
          onFocus={() => setFocused("fullName")}
          onBlur={() => setFocused(false)}
          readOnly={focused !== "fullName"}
          name="fullName"
          onChange={onChange}
          className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none border border-[#DFE1E6] rounded-md text-sm"
        />
      </div>
      <div className="mb-2">
        <p className="text-sm text-[#3F3D56] mb-1">Email</p>
        <input
          type={"text"}
          onFocus={() => setFocused("email")}
          onBlur={() => setFocused(false)}
          readOnly={focused !== "email"}
          name="email"
          onChange={onChange}
          className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none border border-[#DFE1E6] rounded-md text-sm"
        />
      </div>
      <div className="mb-2">
        <p className="text-sm text-[#3F3D56] mb-1">Password</p>
        <input
          type={"password"}
          onFocus={() => setFocused("password")}
          onBlur={() => setFocused(false)}
          readOnly={focused !== "password"}
          name="password"
          onChange={onChange}
          className="focus:border-[#16C6A4] duration-300 w-full py-3 px-2 bg-none outline-none border border-[#DFE1E6] rounded-md text-sm"
        />
      </div>
      <div className="mb-8">
        <p className="text-sm text-[#3F3D56] mb-1">Phone number</p>
        <div className="w-full h-[50px] border border-[#DFE1E6] flex rounded-md">
          <div className="bg-[#DFE1E6]">
            <ReactFlagsSelect
              selected={selected}
              onSelect={(code) => setSelected(code)}
              fullWidth={false}
              showSelectedLabel={false}
              className="ta-menu-flags"
              placeholder=" "
            />
          </div>
          <div className="h-full flex items-center px-3 text-sm no-shrink">
            ({user.mobileExtension})
          </div>
          <input
            type={"text"}
            onFocus={() => setFocused("rawMobile")}
            onBlur={() => setFocused(false)}
            readOnly={focused !== "rawMobile"}
            name="rawMobile"
            onChange={onChange}
            className="focus:border-[#16C6A4] duration-300 w-full py-3 pr-2 bg-none outline-none border-none rounded-md text-sm"
          />
        </div>
      </div>
    </>
  );
};

const Signup = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState({ mobileExtension: "+234" });
  const [focused, setFocused] = useState(false);
  const [step, setStep] = useState(0);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(authActions.register(user));
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setUser((u) => ({
      ...u,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (auth.user) {
      if (auth?.user?.data?.user?.admin) {
        setStep(1);
      }
    }
  }, [auth.user]);

  return (
    <>
      {modal !== false ? (
        <ModalContainer closeModal={() => setModal(false)}>
          {modal === "verification" ? (
            <SignupVerification setModal={setModal} setStep={setStep} />
          ) : (
            ""
          )}
        </ModalContainer>
      ) : (
        ""
      )}
      {step === 0 ? (
        <Auth
          type="signup"
          label="Sign Up"
          subtext={"please fill your correct details."}
          focused={focused}
          onSubmit={onSubmit}
          form={
            <Form
              focused={focused}
              setFocused={setFocused}
              user={user}
              setUser={setUser}
              onChange={onChange}
            />
          }
        />
      ) : (
        <Verification />
      )}
    </>
  );
};

export default Signup;
