import { authConstants } from "constants";

const initialState = {
  user: {},
  registerLoading: false,
  registerError: "",
  loginLoading: false,
  loginError: "",
  forgetPasswordLoading: false,
  forgetPasswordError: "",
  forgetPasswordSuccess: "",
  resetPasswordLoading: false,
  resetPasswordError: "",
  resetPasswordSuccess: "",
  sendVerifySuccess: "",
  sendVerifyLoading: false,
  sendVerifyError: "",
  verifyUserSuccess: false,
  verifyUserLoading: false,
  verifyUserError: "",
  updateRequestLoading: false,
  updateRequestError: "",
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.REGISTER_REQUEST:
      return {
        ...state,
        registerLoading: true,
      };
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        user: action.user,
      };
    case authConstants.REGISTER_FAILURE:
      return {
        ...state,
        registerLoading: false,
        registerError: action.error,
      };
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        loginLoading: false,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: action.error,
      };
    case authConstants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        forgetPasswordLoading: true,
      };
    case authConstants.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPasswordLoading: false,
        forgetPasswordSuccess: action.payload,
      };
    case authConstants.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgetPasswordLoading: false,
        resetError: action.payload,
      };
    case authConstants.RESET_REQUEST:
      return {
        ...state,
        resetPasswordLoading: true,
      };
    case authConstants.RESET_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordSuccess: action.payload,
      };
    case authConstants.RESET_ERROR:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.payload,
      };
    case authConstants.VERIFY_REQUEST:
      return {
        ...state,
        verifyUserLoading: true,
      };
    case authConstants.VERIFY_SUCCESS:
      return {
        ...state,
        verifyUserLoading: false,
        verifyUserSuccess: action.payload,
      };
    case authConstants.VERIFY_ERROR:
      return {
        ...state,
        verifyUserLoading: false,
        verifyUserError: action.payload,
      };
    case authConstants.SEND_VERIFY_REQUEST:
      return {
        ...state,
        sendVerifyLoading: true,
      };
    case authConstants.SEND_VERIFY_SUCCESS:
      return {
        ...state,
        sendVerifyLoading: false,
        sendVerifySuccess: action.payload,
      };
    case authConstants.SEND_VERIFY_ERROR:
      return {
        ...state,
        sendVerifyLoading: false,
        sendVerifyError: action.payload,
      };
    case authConstants.UPDATE_USER_REQUEST:
      return {
        ...state,
        updateRequestLoading: true,
      };
    case authConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateRequestLoading: false,
        user: { ...state.user, ...action.payload.data },
      };
    case authConstants.UPDATE_USER_ERROR:
      return {
        ...state,
        updateRequestLoading: false,
        updateRequestError: action.payload,
      };
    default:
      return state;
  }
}
