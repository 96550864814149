import React, { useEffect, useRef, useState } from "react";
import UserAnalytics from "./Analytics";
import UserList from "./List";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as ChartIcon } from "assets/images/dashboard-icons/chart-3.svg";
import { ReactComponent as Check } from "assets/images/dashboard-icons/check.svg";
import { userActions } from "actions";

const User = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [offset, setOffset] = useState({ width: 0, left: 0 });
  const user = useSelector((state) => state.user);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const item1 = useRef();
  const item2 = useRef();

  useEffect(() => {
    if (user?.usersLoading === false) {
      dispatch(userActions.getUsers({}, true));
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = (page) => {
    if (user?.usersLoading === false) {
      dispatch(userActions.getUsers({ page }));
    }
  };

  useEffect(() => {
    if (step === 1) {
      setOffset({
        width: item1?.current?.offsetWidth,
        left: 0,
      });
    }
    if (step === 2) {
      setOffset({
        width: item2?.current?.offsetWidth,
        left: item1?.current?.offsetWidth,
      });
    }
  }, [step]);

  return (
    <>
      <div className="p-8 pb-0 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg mb-8">
          <div className="relative h-[35px] flex cursor-pointer select-none">
            <div
              className={`h-[35px] bg-[#16C6A4] absolute top-0 duration-300`}
              style={{ width: `${offset.width}px`, left: `${offset.left}px` }}
            ></div>
            <div
              ref={item1}
              onClick={() => setStep(1)}
              className={`h-[35px] duration-300 flex items-center px-8 relative ${
                step === 1 ? "text-white" : "text-[#142837]"
              }`}
            >
              User stats
            </div>
            <div
              ref={item2}
              onClick={() => setStep(2)}
              className={`h-[35px] duration-300 flex items-center px-8 relative ${
                step === 2 ? "text-white" : "text-[#142837]"
              }`}
            >
              Admin stats
            </div>
          </div>
        </div>
        <div className="text-right">
          <p className="text-xs">Today</p>
          <p className="font-bold">
            <Moment format="MMMM DD, YYYY"></Moment>
          </p>
        </div>
      </div>
      <div className="px-8 flex mb-4 relative">
        <div className="h-[40px] w-[40px] rounded-l mr-2 bg-[#16C6A4] flex items-center justify-center">
          <ChartIcon className="h-[10px] w-auto" />
        </div>
        <div className="h-[40px] px-4 pr-6 mr-2 flex bg-white">
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
            placeholderText="Choose date range"
          />
        </div>
        <div className="h-[40px] w-[40px] rounded-r bg-[#16C6A4] flex items-center justify-center">
          <Check className="h-[15px] w-auto cursor-pointer" />
        </div>
      </div>
      <UserAnalytics />
      <div className="p-8 pb-0 mt-4 flex justify-between">
        <div className="p-2 bg-white max-w-fit rounded-lg">
          <div className="h-[35px] bg-[#16C6A4] text-white flex items-center px-8">
            User List
          </div>
        </div>
      </div>
      <UserList fetchData={fetchData} />
    </>
  );
};

export default User;
