import { configureStore } from "@reduxjs/toolkit";
import { auth, dashboard, shipment, quote, payment, user } from "reducers";

export const store = configureStore({
  reducer: {
    auth,
    dashboard,
    shipment,
    quote,
    payment,
    user,
  },
});
